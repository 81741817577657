$blue-main: #00a5f6;
$green-main: #19ae73;
$yellow-main: #f9c003;
$red-main: #ff2913;

div.confirmation {
  max-width: 700px;
  margin: 8px;
  padding: 32px 40px 32px 32px;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: var(--font-secondary);
  transition: all 200ms ease-in-out;
  &--hidden {
    box-shadow: none;
    transform: scale(0.8) translateY(24px);
    opacity: 0;
  }
  &--displaying {
    box-shadow: rgba(0, 0, 0, 0.6);
    transform: scale(1) translateY(0);
    opacity: 1;
  }

  display: flex;
  align-items: flex-start;

  i {
    font-size: var(--font-size-4);
  }

  > div {
    margin-left: 16px;

    > h1 {
      margin: 0 0 8px 0;
      font-size: 18px;
      color: #1b3f4e;
    }

    > p {
      font-size: var(--font-size-1);
      color: #4d6975;
    }

    > div.confirmation-button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;

      > button {
        margin-left: 8px;
      }

      > button.button--tertiary {
        color: #4d6975;
        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }

  // type styles
  &--info {
    > i {
      color: $blue-main;
    }

    > div > div.confirmation-button-container {
      > button.button--primary {
        background-color: $blue-main;
      }
    }
  }
  &--success {
    > i {
      color: $green-main;
    }

    > div > div.confirmation-button-container {
      > button.button--primary {
        background-color: $green-main;
      }
    }
  }
  &--warning {
    > i {
      color: $yellow-main;
    }

    > div > div.confirmation-button-container {
      > button.button--primary {
        background-color: $yellow-main;
      }
    }
  }
  &--destructive {
    > i {
      color: $red-main;
    }

    > div > div.confirmation-button-container {
      > button.button--primary {
        background-color: $red-main;
      }
    }
  }
}
