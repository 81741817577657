:root {
  --brand-dark-blue-tint: #033c54;
  --brand-dark-blue: #022a3b;
  --brand-dark-blue-shadow: #011c27;
  --brand-blue-tint: #3bf;
  --brand-blue: #0af;
  --brand-blue-shadow: #08c;
  --brand-blue-darker-shadow: #006fa6;
  --brand-orange-tint: #ff7429;
  --brand-orange: #ff5a00;
  --brand-orange-shadow: #e65100;
  --brand-yellow-tint: #ffce33;
  --brand-yellow: #ffc200;
  --brand-yellow-shadow: #e6af00;
  --violet-100: #361e67;
  --violet-90: #3e2277;
  --violet-80: #49288a;
  --violet-70: #5e34b2;
  --violet-60: #6a40bf;
  --violet-50: #7953c6;
  --violet-40: #9779d2;
  --violet-30: #b5a0df;
  --violet-20: #d2c6ec;
  --violet-10: #f0ecf9;
  --purple-100: #632b6e;
  --purple-90: #773384;
  --purple-80: #843a92;
  --purple-70: #9440a5;
  --purple-60: #a548b7;
  --purple-50: #ae5abe;
  --purple-40: #c07fcd;
  --purple-30: #d2a3db;
  --purple-20: #e4c8e9;
  --purple-10: #f6edf8;
  --red-100: #a51104;
  --red-90: #bd1405;
  --red-80: #c71505;
  --red-70: #e01806;
  --red-60: #ff1500;
  --red-50: #ff291a;
  --red-40: #ff5b4d;
  --red-30: #ff8a80;
  --red-20: #ffb9b3;
  --red-10: #ffe8e5;
  --orange-100: #a53d03;
  --orange-90: #bd4604;
  --orange-80: #d64f05;
  --orange-70: #e65100;
  --orange-60: #ff5a00;
  --orange-50: #ff6b1a;
  --orange-40: #ff8c4d;
  --orange-30: #ffad80;
  --orange-20: #ffceb3;
  --orange-10: #ffefe6;
  --yellow-100: #8d6c01;
  --yellow-90: #b18702;
  --yellow-80: #c89904;
  --yellow-70: #e0ac05;
  --yellow-60: #f9bf06;
  --yellow-50: #ffc81a;
  --yellow-40: #ffd44d;
  --yellow-30: #ffe180;
  --yellow-20: #ffedb3;
  --yellow-10: #fff9e6;
  --green-100: #0c452e;
  --green-90: #105b3c;
  --green-80: #136c47;
  --green-70: #178255;
  --green-60: #1b9864;
  --green-50: #1fad72;
  --green-40: #2dd28d;
  --green-30: #93ecc7;
  --green-20: #bef4dd;
  --green-10: #e9fbf4;
  --cyan-100: #005580;
  --cyan-90: #069;
  --cyan-80: #0081c2;
  --cyan-70: #0092db;
  --cyan-60: #00a3f5;
  --cyan-50: #1ab3ff;
  --cyan-40: #4dc4ff;
  --cyan-30: #80d5ff;
  --cyan-20: #b3e6ff;
  --cyan-10: #e6f7ff;
  --blue-100: #00308f;
  --blue-90: #003cb3;
  --blue-80: #04c;
  --blue-70: #004de6;
  --blue-60: #05f;
  --blue-50: #1a66ff;
  --blue-40: #4d88ff;
  --blue-30: #80aaff;
  --blue-20: #b3ccff;
  --blue-10: #e6eeff;
  --grey-100: #022a3b;
  --grey-90: #1b3f4e;
  --grey-80: #355562;
  --grey-70: #4d6975;
  --grey-60: #677f89;
  --grey-50: #80949d;
  --grey-40: #9aaab1;
  --grey-30: #b3bfc4;
  --grey-20: #ccd4d8;
  --grey-10: #e5e9eb;
  --grey-5: #f2f4f5;
  --grey-0: #f9fafb;
  --elevation-xss: 0 0 0 1px rgb(0 0 0 / 5%);
  --elevation-xs: 0 1px 2px 0 rgb(0 0 0 / 5%);
  --elevation-s: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  --elevation: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  --elevation-m: 0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
  --elevation-l: 0 20px 25px -5px rgb(0 0 0 / 10%),
    0 10px 10px -5px rgb(0 0 0 / 4%);
  --elevation-xl: 0 25px 50px -12px rgb(0 0 0 / 25%);
  --bg-opacity-grey-4: rgb(2 42 59 / 4%);
  --bg-opacity-grey-8: rgb(2 42 59 / 8%);
  --bg-opacity-grey-12: rgb(2 42 59 / 12%);
  --bg-opacity-grey-16: rgb(2 42 59 / 16%);
  --bg-opacity-grey-20: rgb(2 42 59 / 20%);
  --bg-opacity-grey-24: rgb(2 42 59 / 24%);
  --bg-opacity-grey-32: rgb(2 42 59 / 32%);
  --bg-opacity-grey-40: rgb(2 42 59 / 40%);
  --bg-opacity-white-4: rgb(255 255 255 / 40%);
  --bg-opacity-white-8: rgb(255 255 255 / 80%);
  --bg-opacity-white-12: rgb(255 255 255 / 12%);
  --bg-opacity-white-16: rgb(255 255 255 / 16%);
  --bg-opacity-white-20: rgb(255 255 255 / 20%);
  --bg-opacity-white-24: rgb(255 255 255 / 24%);
  --bg-opacity-white-32: rgb(255 255 255 / 32%);
  --bg-opacity-white-40: rgb(255 255 255 / 40%);
  --cyan-5: #e5f7ff;
  --red-5: #ffb9b3;
  --color-text-cyan: var(--colors-cyan-90);
  --color-text-secondary: var(--text-secondary);
  --color-text-primary: var(--text-primary);
  --color-inactive: #bac4ca;
  --color-success: var(--colors-green-80);
}
