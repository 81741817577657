.errorPage {
  overflow-y: hidden;
  overflow-x: hidden;
  &-main {
    height: 100vh;
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--space);
  }
  &--background {
    background-color: var(--grey-0);
  }

  .title {
    margin: 0 0 var(--space) 0;
    display: flex;
  }

  .subhead {
    font-weight: var(--font-weight-normal);
    margin-bottom: 24px;
  }

  .search {
    margin-top: var(--space);
    margin-bottom: var(--space-l);
  }

  &-image {
    display: grid;
    align-items: end;
    justify-items: center;
    grid-template-columns: 1fr;

    img {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }

  &-background,
  &-travolta {
    width: 100%;
  }

  &-background {
    max-width: 560px;
  }

  &-travolta {
    max-width: 490px;
  }

  &-options {
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--space);

    .link {
      margin-top: var(--space);
      text-decoration: underline !important;
      color: #08c;
      &:visited {
        color: #08c;
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.8%);

  &.hasPadding {
    padding: var(--space-l);
  }
}

@media only screen and (max-width: 1240px) {
  .errorPage-main {
    grid-template-columns: minmax(auto, 650px) auto;
  }
}

@media only screen and (max-width: 900px) {
  .errorPage {
    &-main {
      grid-template-columns: 1fr;
      align-content: start;
      gap: 4rem;
    }

    &-image {
      grid-row-start: 1;
    }

    &-travolta {
      max-width: 300px;
    }

    &-background {
      max-width: 350px;
    }

    .subhead {
      font-size: var(--font-size-2);
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 450px) {
  .errorPage-image {
    display: none;
  }
}
