$blue-main: #00a5f6;
$green-main: #19ae73;
$yellow-main: #f9c003;
$red-main: #ff2913;

div.alert {
  max-width: 480px;
  margin: 8px;
  padding: 32px 40px;
  border-radius: 0;
  -webkit-box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in-out;
  &--hidden {
    box-shadow: none;
    transform: scale(0.8) translateY(24px);
    opacity: 0;
  }
  &--displaying {
    box-shadow: rgba(0, 0, 0, 0.6);
    transform: scale(1) translateY(0);
    opacity: 1;
  }

  font-family: var(--font-secondary);
  text-align: center;
  color: #1b3f4e;

  > i {
    font-size: 40px;
    margin: 0 0 16px 0;
  }
  > h1 {
    font-size: var(--font-size-4);
    margin: 0 0 8px 0;
  }
  > p {
    font-size: var(--font-size-2);
    margin: 0;
  }
  > button {
    margin: 24px 0 0 0;
  }

  // type styles
  &--info {
    > i {
      color: $blue-main;
    }
  }
  &--success {
    > i {
      color: $green-main;
    }
  }
  &--warning {
    > i {
      color: $yellow-main;
    }
  }
  &--error {
    > i {
      color: $red-main;
    }
  }
}
