:root {
  --color-primary: var(--brand-02);
  --color-primary-shade: var(--brand-02-shade);
  --color-secondary: var(--brand-03);
  --color-secondary-shade: var(--brand-03-shade);

  /* Text colors */
  --text-01: var(--grey-100);
  --text-02: var(--grey-80);
  --text-03: var(--grey-50);

  /* Brand colors */
  --brand-01: #022a3b;
  --brand-01-shade: #003b54;
  --brand-02: #0af;
  --brand-02-shade: #08c;
  --brand-03: #ff5a00;
  --brand-03-shade: #e65100;
  --brand-04: #ffc200;
  --brand-04-shade: #e6af00;

  /* Gray colors */
  --gray-01: #677f89;
  --gray-02: #80949d;
  --gray-03: #9aaab1;
  --gray-04: #b3bfc4;
  --gray-05: #ccd4d8;
  --gray-06: #e5e9eb;
  --text-scale-000: calc(var(--typeScale-unit) * 0.75);
  --text-scale-00: calc(var(--typeScale-unit) * 0.875);
  --text-scale-0: calc(var(--typeScale-unit) * 0.75);
  --text-scale-1: var(--typeScale-unit);
  --text-scale-2: calc(var(--typeScale-unit) * 1.25);
  --text-scale-3: calc(var(--typeScale-unit) * 1.5);
  --text-scale-4: calc(var(--typeScale-unit) * 2.25);
  --text-scale-5: calc(var(--typeScale-unit) * 3);
  --text-scale-6: calc(var(--typeScale-unit) * 3.75);
  --text-scale-7: calc(var(--typeScale-unit) * 4);
  --text-scale-8: calc(var(--typeScale-unit) * 4.5);
  --u-error: #cc4752;
  --u-error-hover: #e6505c;
  --u-error-tint: #e6cfd0;
  --u-error-shade: #80131c;
}

p {
  margin: 0;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-normal);
  line-height: 1.5;
}
