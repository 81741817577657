.text {
  margin: 0;
  font-size: fon var(--font-size-2);
  font-weight: var(--font-weight-normal);
  line-height: 1.5;
  color: var(--grey-90);

  &--title {
    font-weight: 700;
  }

  &--bold {
    font-weight: var(--font-weight-semibold);
  }

  &--small {
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-semibold);
    color: var(--grey-90);
  }

  &--secondary {
    color: var(--grey-60);
  }
}

.title {
  font-family: var(--font-primary);
  font-weight: 700;
  color: var(--grey-90);
  font-size: var(--font-size-4);

  &--displayBig {
    font-size: var(--type-scale-100);
    letter-spacing: -2px;
  }

  &--display {
    font-size: var(--type-scale-80);
    letter-spacing: 0;
    line-height: 1.25;
  }

  &--big {
    font-size: var(--type-scale-50);
    color: var(--grey-90);
  }
}

.subhead {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  color: var(--grey-90);
  line-height: 36px;
}

.tagline {
  font-size: f var(--font-size-1);
  font-weight: 700;
  color: var(--grey-90);
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: var(--text-case-uppercase);

  &--secondary {
    color: var(--grey-60);
  }
}
