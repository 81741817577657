@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-index-50);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #0af;

  img.splash-screen-logo {
    width: 140px;
    animation-name: pulse;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
  }
}
