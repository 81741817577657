@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Bold.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Bold.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Bold.svg#GalanoGrotesqueAlt-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Black.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Black.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Black.svg#GalanoGrotesqueAlt-Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-ExtraBold.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-ExtraBold.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-ExtraBold.svg#GalanoGrotesqueAlt-ExtraBold')
      format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-ThinItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-ThinItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-ThinItalic.svg#GalanoGrotesqueAlt-ThinItalic')
      format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-SemiBold.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-SemiBold.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-SemiBold.svg#GalanoGrotesqueAlt-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-BlackItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-BlackItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-BlackItalic.svg#GalanoGrotesqueAlt-BlackItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-ExtraBoldItalic.svg#GalanoGrotesqueAlt-ExtraBoldItalic')
      format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-LightItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-LightItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-LightItalic.svg#GalanoGrotesqueAlt-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-ExtraLight.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-ExtraLight.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-ExtraLight.svg#GalanoGrotesqueAlt-ExtraLight')
      format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Heavy.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Heavy.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Heavy.svg#GalanoGrotesqueAlt-Heavy')
      format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Medium.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Medium.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Medium.svg#GalanoGrotesqueAlt-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-MediumItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-MediumItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-MediumItalic.svg#GalanoGrotesqueAlt-MediumItalic')
      format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-HeavyItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-HeavyItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-HeavyItalic.svg#GalanoGrotesqueAlt-HeavyItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-BoldItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-BoldItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-BoldItalic.svg#GalanoGrotesqueAlt-BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-ExtraLightItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-ExtraLightItalic.svg#GalanoGrotesqueAlt-ExtraLightItalic')
      format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-SemiBoldItalic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-SemiBoldItalic.svg#GalanoGrotesqueAlt-SemiBoldItalic')
      format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Thin.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Thin.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Thin.svg#GalanoGrotesqueAlt-Thin')
      format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Italic.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Italic.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Italic.svg#GalanoGrotesqueAlt-Italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Regular.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Regular.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Regular.svg#GalanoGrotesqueAlt-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../fonts/GalanoGrotesqueAlt-Light.woff2') format('woff2'),
    url('../fonts/GalanoGrotesqueAlt-Light.woff') format('woff'),
    url('../fonts/GalanoGrotesqueAlt-Light.svg#GalanoGrotesqueAlt-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
}
