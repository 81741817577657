body {
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-primary);
  font-size: var(--typeScale-unit);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--grey-90);
}
