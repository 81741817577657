// Breakpoints

:root {
  --xs: 0px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;

  // --xs: (min-width: $xs);
  // --sm: (min-width: $sm);
  // --md: (min-width: $md);
  // --lg: (min-width: $lg);
  // --xl: (min-width: $xl);

  // --xs-only: (min-width: $xs) and (max-width: $sm - 1px);
  // --sm-only: (min-width: $sm) and (max-width: $md - 1px);
  // --md-only: (min-width: $md) and (max-width: $lg - 1px);
  // --lg-only: (min-width: $lg) and (max-width: $xl - 1px);
}
