/*
* Fonts
*/
@import './fonts.scss';

/*
* Deprecated
*/
@import './settings/deprecated.scss';

/*
* Tokens
*/
@import './tokens/colors.scss';
@import './tokens/type-scale.scss';

/*
* Settings
*/
@import './settings/normalize.scss';
@import './settings/colors.scss';
@import './settings/spacing.scss';
@import './settings/settings.scss';
@import './settings/themes.scss';
@import './settings/breakpoints.scss';

/*
* Globals
*/
@import './globals/boxModel.scss';
@import './globals/body.scss';
@import './globals/typography.scss';

iframe[style='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
  pointer-events: none;
}

.main {
  width: 100%;
  max-height: 100vh;
  display: flex;
  grid-template-areas: 'panelSidebar content';
  align-items: stretch;
  overflow-x: none;
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 282px calc(100vw - 282px);
  }
}

.main-sidebar {
  &--collapsed {
    grid-template-columns: 282px calc(100vw - 282px);

    @media only screen and (min-width: 768px) {
      grid-template-columns: 76px auto;
      > .button--primary {
        display: inline;
      }
    }
  }

  &--hidden {
    grid-template-columns: 0 100%;
    > .button--primary {
      display: inline;
    }
  }
}

.content {
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  background-color: var(--grey-0);
  display: flex;
  flex-direction: column;
}

.bodyCustom {
  padding-top: 0 !important;
}
.vitau-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  @media (min-width: 1200px) {
    width: 82%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.nowrap {
  white-space: nowrap;
}

.semibold {
  font-weight: var(--font-weight-semibold);
}

.bold {
  font-weight: bold !important;
}

.panel-ant-tabs {
  .ant-tabs-nav {
    &::before {
      border-bottom: 0;
    }
  }

  .ant-tabs-tab {
    height: 45px;
    margin: 0;
    border: 0;
    padding: 0 24px;
    color: var(--text-primary);
    font-weight: 700;
    border-bottom: 2px solid #bac4ca;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--text-primary);
      text-shadow: none;
    }
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
    background: var(--brand-blue);
  }
}

.wrapper {
  --wrapper-width: 95%;

  padding-right: calc(50vw - (var(--wrapper-width) / 2));
  padding-left: calc(50vw - (var(--wrapper-width) / 2));

  &--hasBackground {
    background-color: #f9fafb;
  }

  @media only screen and (min-width: 1280px) {
    --wrapper-width: 1216px;
  }

  @media only screen and (min-width: 1504px) {
    --wrapper-width: 1440px;
  }
}

.hidden {
  display: none !important;
}

.scrollX {
  overflow-x: scroll;
}

.overflowAutoX {
  overflow-x: auto;
}

/*
* Ant text area
*/

.ant-input::input-placeholder {
  color: var(--text-secondary);
}

.ant-input:placeholder {
  /* Firefox 18- */
  color: var(--text-secondary);
}

.ant-input::placeholder {
  /* Firefox 19+ */
  color: var(--text-secondary);
}

.ant-input:input-placeholder {
  color: var(--text-secondary);
}

.ant-input::placeholder {
  color: var(--text-secondary);
}

.customButtonTertiary {
  background-color: #f1f3f4;
  border: none;
  color: var(--text-primary);
  border-radius: 8px;
  padding: 8px;
  font-size: var(--font-size-0);
  font-family: var(--font-secondary);
  font-weight: bold;
  height: 32px;

  &:hover {
    background-color: #e2e6e9;
  }

  &:active {
    background-color: #cbd3d7;
  }
  &:focus {
    background-color: #cbd3d7;
    box-shadow: none;
  }
}

.fieldsContainer {
  max-height: 65vh;
  overflow: scroll;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.customCheckmark {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #c7cacc;
  border-radius: 50%;
  width: var(--spacing-20);
  height: var(--spacing-20);
  min-width: var(--spacing-20);
  min-height: var(--spacing-20);

  &-selected {
    border-color: transparent;
    background-color: var(--brand-blue);
  }

  i {
    font-size: 10px;
    color: #fafafa;
  }
}
