div.backdrop {
  z-index: var(--z-index-60);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: background-color 200ms linear;
  &--hidden {
    background-color: transparent;
  }
  &--displaying {
    background-color: rgba(0, 0, 0, 0.6);
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
