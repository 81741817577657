// color variables
$blue-main: #00a5f6;
$blue-text: #01669b;
$blue-btn: #005580;
$blue-background: #e7f7ff;

$green-main: #19ae73;
$green-text: #0a6c45;
$green-btn: #136c47;
$green-background: #e9fcf4;

$yellow-main: #f9c003;
$yellow-text: #8e6d01;
$yellow-btn: #8d6c01;
$yellow-background: #fff9e7;

$red-main: #ff2913;
$red-text: #c90d01;
$red-btn: #c71505;
$red-background: #ffe8e7;

div.Toast {
  z-index: var(--z-index-60);
  $spacing: 12px;
  $height: 48px;

  transition: bottom 200ms ease-in-out;
  position: fixed;
  right: $spacing;
  &--hidden {
    bottom: -60px;
  }
  &--displaying {
    bottom: $spacing;
  }

  // styling

  font-family: var(--font-secondary);
  padding: 0 $spacing;
  min-height: $height;
  min-width: 344px;
  max-width: calc(100vw - #{$spacing * 2});
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    padding: $spacing 0;

    p {
      margin: 0 0 0 $spacing;
      padding: 0;
      font-size: var(--font-size-2);
      font-weight: 700;
    }
  }

  button {
    padding: 8px $spacing;
    font-size: var(--font-size-1);
    font-weight: 700;
    text-transform: var(--text-case-uppercase);
    border: none;
    border-radius: 2px;
    background-color: transparent;
    transition: background-color 100ms ease-out;
  }

  // type styles
  &--info {
    color: $blue-text;
    background-color: $blue-background;
    div > i {
      color: $blue-main;
    }
    button {
      color: $blue-btn;
    }
  }

  &--success {
    color: $green-text;
    background-color: $green-background;
    div > i {
      color: $green-main;
    }
    button {
      color: $green-btn;
    }
  }

  &--warning {
    color: $yellow-text;
    background-color: $yellow-background;
    div > i {
      color: $yellow-main;
    }
    button {
      color: $yellow-btn;
    }
  }

  &--error {
    color: $red-text;
    background-color: $red-background;
    div > i {
      color: $red-main;
    }
    button {
      color: $red-btn;
    }
  }

  &--higher {
    margin-bottom: 170px;
  }

  button:focus {
    outline: none;
  }

  &--info > button:focus {
    background-color: rgba($color: $blue-main, $alpha: 0.1);
  }
  &--info > button:hover {
    background-color: rgba($color: $blue-main, $alpha: 0.2);
  }

  &--success > button:focus {
    background-color: rgba($color: $green-main, $alpha: 0.1);
  }
  &--success > button:hover {
    background-color: rgba($color: $green-main, $alpha: 0.2);
  }

  &--warning > button:focus {
    background-color: rgba($color: $yellow-main, $alpha: 0.1);
  }
  &--warning > button:hover {
    background-color: rgba($color: $yellow-main, $alpha: 0.2);
  }

  &--error > button:focus {
    background-color: rgba($color: $red-main, $alpha: 0.1);
  }
  &--error > button:hover {
    background-color: rgba($color: $red-main, $alpha: 0.2);
  }
}
